import { useRef, useState } from "react";
import { useInView, motion } from "framer-motion";
import "./App.css";

const AnimateImageLeft = ({ props }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <img
        src={props.img}
        class="img-fluid"
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      />
    </section>
  );
};

const AnimateImageRight = ({ props }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <img
        src={props.img}
        class="img-fluid"
        style={{
          transform: isInView ? "none" : "translateX(200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      />
    </section>
  );
};

const AnimateImage360 = ({ props }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <img
        src={props.img}
        class="img-fluid"
        style={{
          transform: isInView ? "none" : "translateY(1000px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      />
    </section>
  );
};

const ToggleMenu = ({ props }) => {
  let isActive = false; //[isActive, setActive] = useState(false);

  const toggleClass = (event) => {
    //setActive(false);
    //isActive = false;
    //event.currentTarget.classList.add("active");
    //setActive(!isActive);
  };

  return (
    <span className={isActive ? "active" : null} onClick={toggleClass}>
      {props.text}
    </span>
  );
};

export default function App() {
  return (
    <div className="App">
      <nav id="navbarExample" class="navbar navbar-expand-lg fixed-top navbar-light" aria-label="Main navigation">
        <div class="container-fluid">
          <a class="navbar-brand logo-text" href="#header">
            <img class="img-fluid" src="assets/images/logo.png" alt="logo Facilita Noronha" />
          </a>

          <button class="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
            <ul class="navbar-nav ms-auto navbar-nav-scroll">
              {/* <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#header">
                  <ToggleMenu props={{ text: "Home" }} />
                </a>
              </li> */}
              <li class="nav-item">
                <a class="nav-link" href="#who-we-are">
                  <ToggleMenu props={{ text: "Quem somos" }} />
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#services">
                  <ToggleMenu props={{ text: "Serviços" }} />
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#register">
                  <ToggleMenu props={{ text: "Cadastre-se" }} />
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#contact">
                  <ToggleMenu props={{ text: "Contato" }} />
                </a>
              </li>
            </ul>
            {/* <ul class="navbar-nav ms-auto navbar-nav-scroll">
              <li class="nav-item">
                <a class="nav-link active" href="http://www.facilitanoronha.com.br" target="_blank" rel="noreferrer">
                  Login
                </a>
              </li>
            </ul> */}
          </div>
        </div>
      </nav>

      <div id="header" class="_cards-1 bg-white">
        <div class="container-fluid">
          <div id="smart-solutions" class="row">
            <div class="col-lg-6 col-xl-6">
              <div class="text-container left">
                <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 3 }}>
                  <p class="p-title1">Seja bem-vindo!</p>
                  <p class="p-title2 green">Facilita</p>
                  <p class="p-title2 blue">Noronha</p>
                  <p class="p-title1">Descubra tudo em um só lugar!</p>
                  <p class="p-title1 red">
                    Em breve! <br />
                    Estamos em desenvolvimento...
                  </p>
                </motion.div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-6 right">
              <div class="image-container text-center">
                <motion.div
                  animate={{
                    scale: [0.7, 2, 2, 0.7, 0.7],
                    rotate: [0, 0, 270, 270, 0],
                    borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                  }}
                  transition={{ duration: 2 }}
                >
                  <img class="_img-fluid" src="assets/images/home-phones.png" />
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="who-we-are" class="cards-1 bg-green">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-12">
              <p class="p-title1">Quem somos</p>

              <div class="row mt-5">
                <p class="p-text">
                  Com o intuito de ofecer serviços e praticidade em Fernando de Noronha, <br />
                  criamos o App Facilita Noronha. <br />
                  Somos uma plataforma de multiplas funcionalidades <br />
                  onde reunimos tudo em um só lugar.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="services" class="cards-1 bg-white">
        <div class="container-fluid">
          <div class="row">
            <p class="p-title1 green mb-2">
              Serviços <span class="blue">e utilidades</span>
            </p>
          </div>
          <div class="row">
            <div class="col-lg-6 col-xl-5 left">
              <AnimateImageLeft props={{ img: "assets/images/bennefits-phone.png" }} />
            </div>
            <div class="col-lg-6 col-xl-7 right mt-4">
              <div class="row box-services">
                <div class="row mb-5">
                  <div class="col-md-2">
                    <AnimateImageRight props={{ img: "assets/images/icon-delivery.png" }} />
                  </div>

                  <div class="col-md-10">
                    <p class="title1">Pedidos delivery</p>
                    <p class="title2">
                      Peça e receba alimentos e medicamentos <br />
                      no conforto da sua localização.
                    </p>
                  </div>
                </div>

                <div class="row mb-5">
                  <div class="col-md-2">
                    <AnimateImageRight props={{ img: "assets/images/icon-tour.png" }} />
                  </div>
                  <div class="col-md-10">
                    <p class="title1">Conheça a ilha.</p>
                    <p class="title2">
                      Compre passeios, trilhas <br />e alugue de veículos.
                    </p>
                  </div>
                </div>

                <div class="row mb-5">
                  <div class="col-md-2">
                    <AnimateImageRight props={{ img: "assets/images/icon-device-info.png" }} />
                  </div>
                  <div class="col-md-10">
                    <p class="title1">Utilitários e dicas</p>
                    <p class="title2">
                      Informações úteis, dicas e curiosidades <br />
                      sobre a ilha
                    </p>
                  </div>
                </div>

                <div class="row mb-5">
                  <div class="col-md-2">
                    <AnimateImageRight props={{ img: "assets/images/icon-job.png" }} />
                  </div>
                  <div class="col-md-10">
                    <p class="title1">Trabalhe na ilha</p>
                    <p class="title2">
                      Quer trabalhar na ilha mais linda do país?
                      <br />
                      Confira aqui as ofertas disponíveis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="register" class="cards-1">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 left">
              <h1 class="mb-5">Cadastre-se</h1>
              <p>
                Cadastre o seu estabelecimento <br />
                ou anuncie oportunidades de emprego!
              </p>
            </div>
            <div class="col-lg-6 right">
              <div class="form-group">
                <label>Perfil</label>
                <select class="form-control">
                  <option value="">Selecione</option>
                  <option value="1">Estabelecimento</option>
                  <option value="2">Entregador</option>
                  <option value="3">Profissional</option>
                </select>
              </div>
              <div class="form-group">
                <label>Nome</label>
                <input type="text" class="form-control" placeholder="Nome" />
              </div>
              <div class="form-group">
                <label>E-mail</label>
                <input type="text" class="form-control" placeholder="E-mail" />
              </div>
              <div class="form-group">
                <label>Telefone (Whatsapp)</label>
                <input type="text" class="form-control" placeholder="Telefone (Whatsapp)" />
              </div>
              <div class="form-group">
                <label>Descrição</label>
                <textarea class="form-control" rows={3} placeholder="Descrição"></textarea>
              </div>
              <div class="form-group">
                <button class="btn btn-primary">Enviar</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="contact" class="cards-1">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 left">
              <h1 class="mb-5">Contato</h1>
              <p>
                Dúvidas ou sugestões?
                <br />
                Entre em contato conosco!
              </p>
              {/* <p>
                Cadastre o seu estabelecimento <br />
                ou anuncie oportunidades de emprego!
              </p> */}

              <div class="mt-5">
                <img src="assets/images/available-google-play.png" alt="Baixar no Google Play" width={"200px"} /> &nbsp;
                <img src="assets/images/available-apple-store.png" alt="Baixar na Apple Store" width={"200px"} />
              </div>
            </div>
            <div class="col-lg-6 right">
              <p>
                <img src="assets/images/icon-whatsapp.png" class="img-fluid" alt="icon-whatsapp" /> &nbsp;
                <a href="https://wa.me/+5581994334505?text=Olá! Gostaria de saber mais sobre o App Facilita Noronha" target="_blank" rel="noreferrer">
                  +55 81 9 9433-4505
                </a>
              </p>

              <p>
                <img src="assets/images/icon-email.png" class="img-fluid" alt="icon-email" /> &nbsp; facilitanoronha@gmail.com
              </p>

              <p>
                <img src="assets/images/icon-location.png" class="img-fluid" alt="icon-location" /> &nbsp; Rua da Consolação, Floresta Nova <br />
                Fernando de Noronha - PE, Brasil.
              </p>
            </div>
          </div>
        </div>
      </div>

      <button id="myBtn">
        <img src="assets/images/up-arrow.png" alt="Up Arrow" />
      </button>
    </div>
  );
}
